<template>
  <v-container>
    <v-row>
      <v-col>
        <RoutingType v-on:chooseThisType="chooseThisType" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import RoutingType from "@/components/RoutingType";
import services from "@/services/services.js";

export default {
  components: { RoutingType },
  computed: {
    ...mapState({
      board: (state) => state.board,
    }),
  },
  beforeCreate() {
    const job = null;
    this.$store.commit("SET_JOB", {
      job,
    });
  },
  created() {
    this.updateRoutingTypes();
  },
  methods: {
    chooseThisType(type) {
      this.board.columns[1].routingTypeId = type.id;
      this.board.columns[1].routingType = type;
      this.$router.push({ name: "templates/routingTemplates" });
    },
    updateRoutingTypes() {
      services
        .getRoutingTypes(true)
        .then((response) => {
          this.$store.commit("SET_ROUTINGTYPES", {
            response,
          });
        })
        .catch((error) => {
          this.$store.commit("SAVE_ERROR", { error });
          console.log(error.response.data.title);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
